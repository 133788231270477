import { handleErrorWithSentry, thirdPartyErrorFilterIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  environment: import.meta.env.PUBLIC_NODE_ENV,
  attachStacktrace: true,
  // integrations: [
  //   Sentry.thirdPartyErrorFilterIntegration({
  //     filterKeys: ['totalcsgo-svelte-bundle'],
  //     behaviour: 'drop-error-if-contains-third-party-frames',
  //   }),
  // ],
});

const handleErrorAfterSentry = ({ error, event }: any) => {
  console.log(error);

  return {
    message: 'Whoops!',
    code: error?.code ?? 'UNKNOWN',
  };
};

export const handleError = handleErrorWithSentry(handleErrorAfterSentry);
